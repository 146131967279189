import { useCallback, useContext, useEffect, useState } from 'react';
import { Profile } from 'lib/types/profileType';
import { setError } from 'context/state/reducer';
import { AppContext } from 'context/GlobalContextProvider';
import { COOKIE_CODE_INFO, ACCOUNT_COOKIE_NAME_NATION } from 'constants/index';
import { setConfigToProfile } from './helper';
import { profileConfig } from './configs';
import useFlowManager from '../hooks/useFlowManager';

type Props = {
  profile: Profile;
  existingEmail?: boolean;
};

const useSetUserLoggedInOrRegistered = ({
  profile,
  existingEmail,
}: Props): {
  shouldRedirectToMvpd: boolean;
  shouldRedirectToSuccess: boolean;
} => {
  const [shouldRedirectToMvpd, setShouldRedirectToMvpd] = useState(false);
  const [shouldRedirectToSuccess, setShouldRedirectToSuccess] = useState(false);
  const { accessToken, isMVPD, code, cookies, isNation } = useFlowManager();

  const {
    dispatch,
    state: {
      isRegister: { value: isRegister },
    },
  } = useContext(AppContext);

  const handleLoggedInOrRegistered = useCallback(async () => {
    const skipMVPD = () => {
      if (window?.location?.href) {
        return window?.location.href.indexOf('skip') !== -1;
      }
      return false;
    };
    const nationAccessToken = cookies[ACCOUNT_COOKIE_NAME_NATION]?.accessToken;
    const updatedProfile = (await setConfigToProfile(profile, {
      appName: profileConfig.appName,
      appVersion: profileConfig.appVersion,
      accessToken: isNation ? nationAccessToken : accessToken,
    })) as Profile;
    try {
      if (code) {
        if (
          existingEmail ||
          isRegister === false ||
          cookies[COOKIE_CODE_INFO]?.isRegister === false
        ) {
          // Sign In - isRegister = false
          const res =
            updatedProfile && (await updatedProfile.userLoggedInOrRegistered(code, false));
          const shouldRedirectToSuccess = !!(res?.mvpdId && res?.apiKey);
          setShouldRedirectToSuccess(shouldRedirectToSuccess);
        } else {
          const res =
            updatedProfile &&
            (await updatedProfile.userLoggedInOrRegistered(code, isRegister === true)); // Sign Up - isRegister = true
          const shouldRedirectToMvpd = isMVPD || !res.mvpdId || !skipMVPD;
          setShouldRedirectToMvpd(shouldRedirectToMvpd);
          const shouldRedirectToSuccess = !!(res?.mvpdId && res?.apiKey);
          setShouldRedirectToSuccess(shouldRedirectToSuccess);
        }
      }
    } catch (e) {
      dispatch(setError(e));
    }
  }, [accessToken, code, cookies, dispatch, existingEmail, isMVPD, isRegister, isNation, profile]);

  useEffect(() => {
    if (!profile?.userLoggedInOrRegistered || isMVPD) return;
    if (accessToken || isNation) {
      handleLoggedInOrRegistered();
    }
  }, [
    accessToken,
    handleLoggedInOrRegistered,
    isMVPD,
    isNation,
    profile?.userLoggedInOrRegistered,
  ]);

  return {
    shouldRedirectToMvpd,
    shouldRedirectToSuccess,
  };
};

export default useSetUserLoggedInOrRegistered;
